import React from 'react';
import './encabezado.css';  // Importa el archivo CSS
import foto1 from './imagenes/carrusel1.png';
import foto2 from './imagenes/carrusel2.png';
import foto3 from './imagenes/carrusel3.png';
import cabecera1 from './imagenes/cabecera1.png';


// Definición de un componente funcional
const Encabezado = () => {
  return (
    <div>
        <div className="cabecera">
            <a href="/contacto" target="_blank" rel="noopener noreferrer">
                <img src={cabecera1} alt="Cabecera" />
            </a>
        </div>
        
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarTogglerDemo01">

            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
                <a class="nav-link" href="/">
                  HOME
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/inicial">
                  INICIAL
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/primaria">
                  PRIMARIA
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/secundaria">
                  SECUNDARIA
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/administracion">
                  ADMINISTRACIÓN
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/contacto">
                  CONTACTO
                </a>
              </li>

            </ul>

          </div>
        </div>
      </nav>
      <div
        id="carouselExampleIndicators"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src={foto1} class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item">
            <img src={foto2} class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item">
            <img src={foto3} class="d-block w-100" alt="..." />
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Encabezado;