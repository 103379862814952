import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './plantilla.css'; // Importa tu archivo de estilos CSS
import Footer from '../components/footer/footer';
import EncabezadoSincarrusel from '../components/encabezado/encabezado_sincarrusel';


function Padministracion() {
  const { id } = useParams();
  const [novedad, setNovedad] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('https://frenoux1.pythonanywhere.com/api/productos')
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error en la solicitud: ${response.status} ${response.statusText}`);
        }
        return response.json();
      })
      .then((data) => {
        const novedadSeleccionada = data.find((item) => item.id === parseInt(id));

        if (novedadSeleccionada) {
          // Manejar la cadena JSON dentro de galeria
          const galeriaArray = JSON.parse(novedadSeleccionada.galeria);
          novedadSeleccionada.galeria = galeriaArray;

          setNovedad(novedadSeleccionada);
        } else {
          setError('Novedad no encontrada');
        }
      })
      .catch((error) => {
        console.error('Error al obtener datos:', error);
        setError('Error al obtener datos');
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) {
    return <p>Cargando...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!novedad) {
    return <p>No se encontró la novedad</p>;
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Se suma 1 porque los meses en JavaScript van de 0 a 11
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  };

  return (
    <div className="Plantilla">
      <EncabezadoSincarrusel />
      <h2 className="text-center mb-4">Nivel: {novedad.categoria}</h2>
      <h2 className="titulo">{novedad.nombre}</h2>
      <p>Fecha de Publicación: {formatDate(novedad.fecha_publicacion)}</p>
      <p className="id">ID: {novedad.id}</p>
      <p className="mensaje">{novedad.memo}</p>
      <br /><br />

      <div className="galeria">
        {novedad.galeria.map((imagen, index) => (
          <img
            key={index}
            src={`https://frenoux1.pythonanywhere.com/static/assets/fotos_productos/${imagen}`}
            alt={`Galeria ${index}`}
            
          />
        ))}
      </div>

      <Footer />
    </div>
  );
}

export default Padministracion;


