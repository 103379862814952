import React from 'react';
import './encabezado.css';  // Importa el archivo CSS
import cabecera1 from './imagenes/cabecera1.png';

// Definición de un componente funcional
const EncabezadoSincarrusel = () => {
  return (
    <div>
        <div className="cabecera">
            <a href="/contacto" target="_blank" rel="noopener noreferrer">
                <img src={cabecera1} alt="Cabecera" />
            </a>
        </div>
        
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarTogglerDemo01"
                    aria-controls="navbarTogglerDemo01"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarTogglerDemo01">

                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a className="nav-link" href="/">
                                HOME
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/inicial">
                                INICIAL
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/primaria">
                                PRIMARIA
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/secundaria">
                                SECUNDARIA
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/administracion">
                                ADMINISTRACIÓN
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/contacto">
                                CONTACTO
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
  );
};

export default EncabezadoSincarrusel;
