import { FaWhatsapp } from 'react-icons/fa';
import './WhatsappIcon.css'; // Archivo de estilos CSS

const WhatsappIcon = () => {
    const phoneNumber = '+541123009864';  // Número de teléfono para WhatsApp
    const message = 'Hola, quiero consultar algo.';  // Mensaje predefinido

    // URL de WhatsApp con número de teléfono y mensaje predefinido
    const whatsappUrl = `https://api.whatsapp.com/send/?phone=${encodeURIComponent(phoneNumber)}&text=${encodeURIComponent(message)}`;

    return (
        <a href={whatsappUrl} className="whatsapp-icon" target="_blank" rel="noopener noreferrer">
            <FaWhatsapp />
        </a>
    );
};

export default WhatsappIcon;
