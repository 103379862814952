import Encabezado from "./components/encabezado/encabezado";
import Footer from "./components/footer/footer";
import "./App.css"; // Importa el archivo CSS


// Resto de tus importaciones y código
import ComponenteHome from './paginas/componenteHome';

function App() {
  return (
    <div>
      <Encabezado />
      <div className="App">
        <div>
          {/* Sección de Bienvenida */}
          <section className="bg-light py-5">
            <div className="container text-center">
              <h1 className="display-4">30 años formando buenas personas</h1>
              <p className="lead">Descubre nuestras propuestas educativas.</p>
            
            </div>
            <ComponenteHome/>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
