// En tu componente o archivo principal
import 'bootstrap/dist/css/bootstrap.min.css';

// En tu componente o archivo principal (después de importar el CSS)
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


// Resto de tus importaciones y código

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import Inicial from './paginas/inicial';
import Primaria from './paginas/primaria';
import Secundaria from './paginas/secundaria';
import Contacto from './paginas/contacto';

import Psecundaria from './paginas/psecundaria';
import Pprimaria from './paginas/pprimaria';
import Pinicial from './paginas/pinicial';
import PcomponenteHome from './paginas/pcomponenteHome';
import ComponenteHome from './paginas/componenteHome';
import Administracion from './paginas/administracion';
import Padministracion from './paginas/padministracion';

import{BrowserRouter, Route, Routes} from"react-router-dom";




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>

  <Routes>
    <Route path='/' element={<App/>} />
    <Route path='/inicial' element={<Inicial/>} />
    <Route path='/primaria' element={<Primaria/>} />
    <Route path='/secundaria' element={<Secundaria/>} />
    <Route path='/contacto' element={<Contacto/>} />
    <Route path='/pinicial/:id' element={<Pinicial />} />
    <Route path='/pprimaria/:id' element={<Pprimaria/>} />
    <Route path='/psecundaria/:id' element={<Psecundaria />} />
    <Route path='/componenteHome' element={<ComponenteHome />} />
    <Route path='/pcomponenteHome/:id' element={<PcomponenteHome />} />
    <Route path='/administracion/' element={<Administracion />} />
    <Route path='/padministracion/:id' element={<Padministracion />} />

    

  </Routes>



  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
