import React from 'react';
import WhatsappIcon from '../WhatsappIcon/WhatsappIcon';



// Definición de un componente funcional
const Footer = () => {
  return (
    <div>
      
      <WhatsappIcon/>

      <footer className="bg-dark text-light py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <h5>Enlaces</h5>
            <ul className="list-unstyled">
              <li><a href="/">Home</a></li>
              <li><a href="/inicial">Inicial</a></li>
              <li><a href="/primaria">Primaria</a></li>
              <li><a href="/secundaria">Secundaria</a></li>
              <li><a href="/administracion">Administración</a></li>
              <li><a href="/contacto">Contacto</a></li>
            </ul>
          </div>
          <div className="col-md-6">
            <h5>Contacto</h5>
            <p><i className="bi bi-geo-alt"></i> Dirección: Madrid 3626
Castelar, Buenos Aires, CP: 1712</p>
            <p><i className="bi bi-envelope"></i> Correo: leloiradm@yahoo.com</p>
            <p><i className="bi bi-phone"></i> Teléfono: (011) 4692 0027 (interno 34)</p>
          </div>
          <div className="col-md-3">
            <h5>Redes Sociales</h5>
            <ul className="list-unstyled">
              
              <li><a href="https://www.instagram.com/institutoleloir_castelar/" target="_blank" rel="noopener noreferrer"><i className="bi bi-instagram"></i> Instagram: institutoleloir_castelar</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="text-center mt-3">
        <p>&copy; Copyright 2023 – Todos los derechos reservados – Desarrollado por Dfmsistemas</p>
      </div>
    </footer>
    </div>
  );
};

export default Footer;