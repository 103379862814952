import React from 'react';
import Footer from '../components/footer/footer';
import EncabezadoSincarrusel from '../components/encabezado/encabezado_sincarrusel';
import inscripcion from '../imagenes/inscripcion-2025.jpeg';
import MapaGoogle from '../components/mapa/mapa';


function Primaria() {
  return (
    <div className="Contacto">
      <EncabezadoSincarrusel />

      <div>
        {/* Sección de Bienvenida */}
        <section className="bg-light py-5">
          <div className="container text-center">
            <h1 className="display-4">Para más información</h1>
            <h2 className="lead">Contactenos.</h2>
            <br /><br />
            <div className="row">
              <div className="col-md-7">
                <img src={inscripcion} alt="Imagen 1" />
              </div>
              <div className="col-md-5">
                <h1>Ubicación</h1>
                <MapaGoogle />
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
}

export default Primaria;