import React from 'react';



const MapaGoogle = () => {
  return (

      <iframe 
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d209967.49679632514!2d-58.65622400000001!3d-34.686457!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcc0d01be5d83d%3A0x453dc8a89bc9245e!2sMadrid%203626%2C%20B1712%20Castelar%2C%20Provincia%20de%20Buenos%20Aires%2C%20Argentina!5e0!3m2!1ses!2sus!4v1700948097911!5m2!1ses!2sus"
        width="500"
        height="530"
        title="Ubicación en el Mapa"
        style={{border:1}}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>

  );
};

export default MapaGoogle;
